import Convert from 'ansi-to-html';
import classNames from 'classnames';
import { LogEvent } from 'common/dist/types/orchestration/logs';
import { ApiError } from 'common/dist/types/responseBodies/errors';
import React, { FC, useEffect, useRef } from 'react';
import ReactLoading from 'react-loading';

import styles from './styles.module.scss';
import vars from '../../../../../scss/base/var.module.scss';
import { LoadableData } from '../../../../utils';

const convert = new Convert({ escapeXML: true });

type Props = {
  logs?: LoadableData<LogEvent[], ApiError>;
  autoScroll?: boolean;
};

const JobLogs: FC<Props> = ({ logs, autoScroll }) => {
  const logsRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (autoScroll && logsRef.current) {
      logsRef.current.scrollTop = logsRef.current.scrollHeight; // Scroll to the bottom
    }
  }, [logs, autoScroll]);

  if (!logs) return null;

  const { loading, loaded, error, data } = logs;

  if (loading) {
    // Loading
    return (
      <div className={styles.jobLogs}>
        <ReactLoading type={'cylon'} color={vars.colorPrimary} />
      </div>
    );
  } else if (error) {
    // Error
    return <div className={styles.jobLogs}>{JSON.stringify(error)}</div>;
  } else if (loaded && (data || []).length === 0) {
    // Loaded but empty
    return (
      <div className={classNames(styles.jobLogs, styles.empty)}>
        <span>There are no logs for this job</span>
      </div>
    );
  } else {
    // Loaded and has data
    return (
      <div className={styles.jobLogs} ref={logsRef}>
        <table>
          {(data || []).map((logEvent, index) => (
            <tr key={index} className={styles.logEvent}>
              <td className={styles.lineNumber}>{logEvent.n}</td>
              <td
                className={styles.logMessage}
                dangerouslySetInnerHTML={{
                  __html: convert.toHtml(logEvent.message),
                }}
              />
            </tr>
          ))}
        </table>
      </div>
    );
  }
};

export default JobLogs;
